.MessageLink {
  background-color: blue;
  margin-bottom: 1rem;
  overflow: hidden scroll;
  color: #FFFFFF;
  height: 6rem;
  border: 1px solid #FFFFFF;
}

.MessageLink .Date {
  padding: 0.5rem;
  display: inline-block;
  overflow: hidden;  
  border: 1px solid #FFFFFF;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  width: 100%;
  font-size: smaller;
}

.MessageLink span {
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  display: inline-block;
}

.MessageLink .FirstChild {
  overflow: hidden;  
  border: 1px solid #FFFFFF;
  border-top: 0;
  border-left: 0;
  width: 50%;
}

.MessageLink .NoSpam, .MessageLink .possibleSpam {
  overflow: hidden;
  border: 1px solid #FFFFFF;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 50%;
}

.MessageLink .ThirdChild {
  border: 1px solid #FFFFFF;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  width: 80%;
}

.MessageLink .FourthChild {
  padding: 0.5rem 0 0;
  text-align: center;
  vertical-align: top;
  width: 20%;
}

.MessageLink .FourthChild svg {
  height: 1rem;
}

.possibleSpam {
  background-image: repeating-linear-gradient(#ff8800, darkviolet 10%, red 30%); 
}

.messageText {
  overflow: hidden;
}
