.App {
  width: 85vw;
  max-width: 1400px;
  margin: 0 auto;

}

.MovingBackground {
  background-color:black;
  background-image:
  radial-gradient(white, rgba(255,255,255,.2) 4px, transparent 2px),
  radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 5px),
  radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 5px),
  radial-gradient(rgba(255,255,255,.9), rgba(255,255,255,.1) 1px, transparent 5px);
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  /* bg position in animation below */

  animation: movement;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes movement {
  from {
    background-position: 0 0, 40px 60px, 130px 20px, 70px 100px;
  }
  to {
    background-position: 500px 0, -320px -60px, -10px -20px, -100px -180px;
  }
}


@media (min-width: 501px) {
  .App {
    width: 80vw;
  }
}

@media (min-width: 1000px) {
  .App {
    width: 800px;
  }
}
