.Nav ul {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 0 auto;
}

.Nav ul li {
  padding: 1rem 0.5rem;
  color: yellow;
  cursor: pointer;
}

@media (min-width: 500px) {
  .Nav ul li {
    padding: 1rem 0;
  }
}

.Nav ul li:hover {
  color: lightblue;
}
