.FormContent {

  padding: 0 1rem;

}

.Form {

  width: 18rem;
  padding: 1rem 1rem;

}

.Form input[type=text],
input[type=email],
input[type=password] {
  width: 98%;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
  box-sizing: border-box;
}


.Form button:disabled {
  background-color: #CCCCCC;
  color: #FFFFFF;
  opacity: 0.75;
}

.Title {
  font-family: inherit;
  position: absolute;
  left: -100vw;
}

.Standard {
  font-family: inherit;
  text-transform: capitalize;
}

.Flash {
  width: 100%;
  margin-top: 1rem;
}


.Success {
  animation: fadein 0.5s ease-in forwards;
  opacity: 0;
  display: block;
  margin: 1rem 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: 400;
  width: 98%;
  background-color: green;
}

.Error {
  animation: fadein 0.5s ease-in forwards;
  opacity: 0;
  display: block;
  margin: 1rem 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: 400;
  width: 98%;
  background-color: red;
}

@keyframes fadein {
  100% {
    opacity: 1
  }
}
