@import url("https://use.typekit.net/cor1mji.css");

html * {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #060c1d;
  color: #FFFFFF;
  font-family: museo-sans, sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 16px;
  /* Starry night */
  /* background-color: #060c1d; */
  background-color:black;
}


@media (min-width: 500px) {
  body {
    background-image:
    radial-gradient(white, rgba(255,255,255,.2) 4px, transparent 2px),
    radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 5px),
    radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 5px),
    radial-gradient(rgba(255,255,255,.9), rgba(255,255,255,.1) 1px, transparent 5px);
    background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
    /* bg position is in animation below */
    animation: movement;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite; 
  }
}

a, a:visited {
  color: yellow;
  text-decoration: none;
  cursor: pointer;
}

strong {
  font-weight: 300;
}

h1 {
  font-family: museo, serif;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

fieldset {
  border: none;
  padding: 0 !important;
  margin: 0;
}

button {
  padding: 0.5rem 1rem;
  float: right;
  background-color: orange;
  border: none;
  border-radius: 5px;
  color: #060c1d;
  cursor: pointer;
}

.error {
  color: red;
}

@keyframes movement {
  from {
    background-position: 0 0, 40px 60px, 130px 20px, 70px 100px;
  }
  to {
    background-position: 500px 0, -320px -60px, -10px -20px, -100px -180px;
  }
}
