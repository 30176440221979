.SingleView {
  animation: fadein 0.5s ease-in forwards;
  opacity: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem auto;
}

.SingleView h1 {
  font-family: bd-geminis, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 2rem;
}

.MessageContent {
  font-family: anonymous-pro,
  monospace;
  font-weight: 400;
  font-style: normal;
}

.MessageContent span {
  display: block;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.possibleSpam {
  text-align: center;
  background-image: repeating-linear-gradient(#ff8800, darkviolet 10%, red 30%);  
}

.Created {
  text-align: right;
}

.delete {
  text-align: center;
}

.delete svg {
  margin: 1rem;
  width: 30px;
  height: 30px;
}

@keyframes fadein {
  100% {
    opacity: 1
  }
}
