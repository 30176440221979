.MainView {
  animation: fadein 0.5s ease-in forwards;
  opacity: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1rem auto;
}

.Sidedrawer {
  margin: 0 auto 1rem;
  width: 80vw;
}

@media (min-width:1000px) {
  .Sidedrawer {
    width: 800px;
  }
}

.NoMessages {
  display: block;
  /* text-align: center; */
  position: fixed;
  top: 20%;
  left: 45vw;
}

.NoMessages span {
  display: block;
  margin-bottom: 2rem;
}

.Sidedrawer h1 {
  display: inline;
  font-family: bd-geminis, sans-serif;
  font-weight: 400;
  margin: 0 auto 1rem;
}

.Sidedrawer button {
  margin-top: 0.5rem;
  margin-left: 1rem;
}

@media (min-width: 360px) {

  .Sidedrawer h1 {
    margin: 0 auto 1rem;
  }

  .Sidedrawer button {
    margin-top: 0;
  }
}

.Messages {
  font-family: anonymous-pro,
  monospace;
  margin: 0 auto;
  width: 80vw;
}

@media (min-width:1000px) {
  .Messages {
    width: 800px;
  }
}

@keyframes fadein {
  100% {
    opacity: 1
  }
}
