@import url(https://use.typekit.net/cor1mji.css);
html * {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #060c1d;
  color: #FFFFFF;
  font-family: museo-sans, sans-serif;
  font-weight: 100;
  font-style: normal;
  font-size: 16px;
  /* Starry night */
  /* background-color: #060c1d; */
  background-color:black;
}


@media (min-width: 500px) {
  body {
    background-image:
    radial-gradient(white, rgba(255,255,255,.2) 4px, transparent 2px),
    radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 5px),
    radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 5px),
    radial-gradient(rgba(255,255,255,.9), rgba(255,255,255,.1) 1px, transparent 5px);
    background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
    /* bg position is in animation below */
    -webkit-animation: movement;
            animation: movement;
    -webkit-animation-duration: 30s;
            animation-duration: 30s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; 
  }
}

a, a:visited {
  color: yellow;
  text-decoration: none;
  cursor: pointer;
}

strong {
  font-weight: 300;
}

h1 {
  font-family: museo, serif;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

fieldset {
  border: none;
  padding: 0 !important;
  margin: 0;
}

button {
  padding: 0.5rem 1rem;
  float: right;
  background-color: orange;
  border: none;
  border-radius: 5px;
  color: #060c1d;
  cursor: pointer;
}

.error {
  color: red;
}

@-webkit-keyframes movement {
  from {
    background-position: 0 0, 40px 60px, 130px 20px, 70px 100px;
  }
  to {
    background-position: 500px 0, -320px -60px, -10px -20px, -100px -180px;
  }
}

@keyframes movement {
  from {
    background-position: 0 0, 40px 60px, 130px 20px, 70px 100px;
  }
  to {
    background-position: 500px 0, -320px -60px, -10px -20px, -100px -180px;
  }
}

.form_FormContent__1dSUZ {

  padding: 0 1rem;

}

.form_Form__3Q1yB {

  width: 18rem;
  padding: 1rem 1rem;

}

.form_Form__3Q1yB input[type=text],
input[type=email],
input[type=password] {
  width: 98%;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
  box-sizing: border-box;
}


.form_Form__3Q1yB button:disabled {
  background-color: #CCCCCC;
  color: #FFFFFF;
  opacity: 0.75;
}

.form_Title__oRKdc {
  font-family: inherit;
  position: absolute;
  left: -100vw;
}

.form_Standard__1BmBx {
  font-family: inherit;
  text-transform: capitalize;
}

.form_Flash__1csnF {
  width: 100%;
  margin-top: 1rem;
}


.form_Success__2m79z {
  -webkit-animation: form_fadein__2Cidk 0.5s ease-in forwards;
          animation: form_fadein__2Cidk 0.5s ease-in forwards;
  opacity: 0;
  display: block;
  margin: 1rem 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: 400;
  width: 98%;
  background-color: green;
}

.form_Error__39CD6 {
  -webkit-animation: form_fadein__2Cidk 0.5s ease-in forwards;
          animation: form_fadein__2Cidk 0.5s ease-in forwards;
  opacity: 0;
  display: block;
  margin: 1rem 0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: 400;
  width: 98%;
  background-color: red;
}

@-webkit-keyframes form_fadein__2Cidk {
  100% {
    opacity: 1
  }
}

@keyframes form_fadein__2Cidk {
  100% {
    opacity: 1
  }
}

.spinner_Spinner__Py3PL {
  text-align: center;
}

.login_Login__2cvMJ {
  -webkit-animation: login_fadein__1Gz5Z 0.5s ease-in forwards;
          animation: login_fadein__1Gz5Z 0.5s ease-in forwards;
  opacity: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 2rem;
}

@-webkit-keyframes login_fadein__1Gz5Z {
  100% {
    opacity: 1
  }
}

@keyframes login_fadein__1Gz5Z {
  100% {
    opacity: 1
  }
}

.nav_Nav__2V1OW ul {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 0 auto;
}

.nav_Nav__2V1OW ul li {
  padding: 1rem 0.5rem;
  color: yellow;
  cursor: pointer;
}

@media (min-width: 500px) {
  .nav_Nav__2V1OW ul li {
    padding: 1rem 0;
  }
}

.nav_Nav__2V1OW ul li:hover {
  color: lightblue;
}

.footer_Footer__20Z3u {
  width: 100%;
  position: fixed;
  bottom: 0;
  opacity: 0;
  -webkit-animation-name: footer_blink__1XU5V;
          animation-name: footer_blink__1XU5V;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

@-webkit-keyframes footer_blink__1XU5V {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes footer_blink__1XU5V {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.messagelink_MessageLink__3Myc3 {
  background-color: blue;
  margin-bottom: 1rem;
  overflow: hidden scroll;
  color: #FFFFFF;
  height: 6rem;
  border: 1px solid #FFFFFF;
}

.messagelink_MessageLink__3Myc3 .messagelink_Date__2PVBT {
  padding: 0.5rem;
  display: inline-block;
  overflow: hidden;  
  border: 1px solid #FFFFFF;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  width: 100%;
  font-size: smaller;
}

.messagelink_MessageLink__3Myc3 span {
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  display: inline-block;
}

.messagelink_MessageLink__3Myc3 .messagelink_FirstChild__1sYTA {
  overflow: hidden;  
  border: 1px solid #FFFFFF;
  border-top: 0;
  border-left: 0;
  width: 50%;
}

.messagelink_MessageLink__3Myc3 .messagelink_NoSpam__PCyXt, .messagelink_MessageLink__3Myc3 .messagelink_possibleSpam__3iyQO {
  overflow: hidden;
  border: 1px solid #FFFFFF;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 50%;
}

.messagelink_MessageLink__3Myc3 .messagelink_ThirdChild__2LQJ0 {
  border: 1px solid #FFFFFF;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  width: 80%;
}

.messagelink_MessageLink__3Myc3 .messagelink_FourthChild__1IKCV {
  padding: 0.5rem 0 0;
  text-align: center;
  vertical-align: top;
  width: 20%;
}

.messagelink_MessageLink__3Myc3 .messagelink_FourthChild__1IKCV svg {
  height: 1rem;
}

.messagelink_possibleSpam__3iyQO {
  background-image: repeating-linear-gradient(#ff8800, darkviolet 10%, red 30%); 
}

.messagelink_messageText__3QegA {
  overflow: hidden;
}

.deletebutton_DeleteButton__2_AH2 svg {
  fill: red;
}

.mainview_MainView__36SCo {
  -webkit-animation: mainview_fadein__2ksiO 0.5s ease-in forwards;
          animation: mainview_fadein__2ksiO 0.5s ease-in forwards;
  opacity: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1rem auto;
}

.mainview_Sidedrawer__3253Y {
  margin: 0 auto 1rem;
  width: 80vw;
}

@media (min-width:1000px) {
  .mainview_Sidedrawer__3253Y {
    width: 800px;
  }
}

.mainview_NoMessages__3appo {
  display: block;
  /* text-align: center; */
  position: fixed;
  top: 20%;
  left: 45vw;
}

.mainview_NoMessages__3appo span {
  display: block;
  margin-bottom: 2rem;
}

.mainview_Sidedrawer__3253Y h1 {
  display: inline;
  font-family: bd-geminis, sans-serif;
  font-weight: 400;
  margin: 0 auto 1rem;
}

.mainview_Sidedrawer__3253Y button {
  margin-top: 0.5rem;
  margin-left: 1rem;
}

@media (min-width: 360px) {

  .mainview_Sidedrawer__3253Y h1 {
    margin: 0 auto 1rem;
  }

  .mainview_Sidedrawer__3253Y button {
    margin-top: 0;
  }
}

.mainview_Messages__Bl0FX {
  font-family: anonymous-pro,
  monospace;
  margin: 0 auto;
  width: 80vw;
}

@media (min-width:1000px) {
  .mainview_Messages__Bl0FX {
    width: 800px;
  }
}

@-webkit-keyframes mainview_fadein__2ksiO {
  100% {
    opacity: 1
  }
}

@keyframes mainview_fadein__2ksiO {
  100% {
    opacity: 1
  }
}

.singleview_SingleView__3pvZt {
  -webkit-animation: singleview_fadein__1dn7A 0.5s ease-in forwards;
          animation: singleview_fadein__1dn7A 0.5s ease-in forwards;
  opacity: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem auto;
}

.singleview_SingleView__3pvZt h1 {
  font-family: bd-geminis, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 2rem;
}

.singleview_MessageContent__jW9pa {
  font-family: anonymous-pro,
  monospace;
  font-weight: 400;
  font-style: normal;
}

.singleview_MessageContent__jW9pa span {
  display: block;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.singleview_possibleSpam__ZQTpp {
  text-align: center;
  background-image: repeating-linear-gradient(#ff8800, darkviolet 10%, red 30%);  
}

.singleview_Created__rpH8x {
  text-align: right;
}

.singleview_delete__1THEV {
  text-align: center;
}

.singleview_delete__1THEV svg {
  margin: 1rem;
  width: 30px;
  height: 30px;
}

@-webkit-keyframes singleview_fadein__1dn7A {
  100% {
    opacity: 1
  }
}

@keyframes singleview_fadein__1dn7A {
  100% {
    opacity: 1
  }
}

.app_App__1o2i5 {
  width: 85vw;
  max-width: 1400px;
  margin: 0 auto;

}

.app_MovingBackground__1jSVf {
  background-color:black;
  background-image:
  radial-gradient(white, rgba(255,255,255,.2) 4px, transparent 2px),
  radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 5px),
  radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 5px),
  radial-gradient(rgba(255,255,255,.9), rgba(255,255,255,.1) 1px, transparent 5px);
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  /* bg position in animation below */

  -webkit-animation: app_movement__2kaL7;

          animation: app_movement__2kaL7;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes app_movement__2kaL7 {
  from {
    background-position: 0 0, 40px 60px, 130px 20px, 70px 100px;
  }
  to {
    background-position: 500px 0, -320px -60px, -10px -20px, -100px -180px;
  }
}

@keyframes app_movement__2kaL7 {
  from {
    background-position: 0 0, 40px 60px, 130px 20px, 70px 100px;
  }
  to {
    background-position: 500px 0, -320px -60px, -10px -20px, -100px -180px;
  }
}


@media (min-width: 501px) {
  .app_App__1o2i5 {
    width: 80vw;
  }
}

@media (min-width: 1000px) {
  .app_App__1o2i5 {
    width: 800px;
  }
}

