.Login {
  animation: fadein 0.5s ease-in forwards;
  opacity: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 2rem;
}

@keyframes fadein {
  100% {
    opacity: 1
  }
}
